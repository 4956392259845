/* eslint-disable prettier/prettier */
const TYPES ={
    ADD_NUCLEO_FAMILIAR: 'ADD_NUCLEO_FAMILIAR',
    ADD_NUCLEO_FAMILIAR_SUCCESS: 'ADD_NUCLEO_FAMILIAR_SUCCESS',
    ADD_NUCLEO_FAMILIAR_ERROR:'ADD_NUCLEO_FAMILIAR_ERROR' ,
    EDITAR_NUCLEO_FAMILIAR: 'EDITAR_NUCLEO_FAMILIAR',
    EDITAR_NUCLEO_FAMILIAR_SUCCESS: 'EDITAR_NUCLEO_FAMILIAR_SUCCESS',
    EDITAR_NUCLEO_FAMILIAR_ERROR: 'EDITAR_NUCLEO_FAMILIAR_ERROR',
    OBTENER_NUCLEO_FAMILIAR: 'OBTENER_NUCLEO_FAMILIAR',
    OBTENER_NUCLEO_FAMILIAR_SUCCESS: 'OBTENER_NUCLEO_FAMILIAR_SUCCESS' ,
    OBTENER_NUCLEO_FAMILIAR_ERROR: 'OBTENER_NUCLEO_FAMILIAR_ERROR',
    DELETE_NUCLEO_FAMILIAR: 'DELETE_NUCLEO_FAMILIAR',
    DELETE_NUCLEO_FAMILIAR_SUCCESS: 'DELETE_NUCLEO_FAMILIAR_SUCCESS',
    DELETE_NUCLEO_FAMILIAR_ERROR: 'DELETE_NUCLEO_FAMILIAR_ERROR',

    TRASLADO_NUCLEO_FAMILIAR: 'TRASLADO_NUCLEO_FAMILIAR',
    TRASLADO_NUCLEO_FAMILIAR_SUCCESS: 'TRASLADO_NUCLEO_FAMILIAR_SUCCESS',
    TRASLADO_NUCLEO_FAMILIAR_ERROR: 'TRASLADO_NUCLEO_FAMILIAR_ERROR',

    ACTIVAR_MIEMBRO_NUCLEO_FAMILIAR: 'ACTIVAR_MIEMBRO_NUCLEO_FAMILIAR',
    ACTIVAR_MIEMBRO_NUCLEO_FAMILIAR_SUCCESS: 'ACTIVAR_MIEMBRO_NUCLEO_FAMILIAR_SUCCESS',
}

export default TYPES
