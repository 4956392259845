/* eslint-disable prettier/prettier */
const TYPES = {
    ADD_MIEMBRO: 'ADD_MIEMBRO',
    ADD_MIEMBRO_SUCCESS: 'ADD_MIEMBRO_SUCCESS',
    ADD_MIEMBRO_ERROR: 'ADD_MIEMBRO_ERROR',
    EDITAR_MIEMBRO: 'EDITAR_MIEMBRO',
    EDITAR_MIEMBRO_SUCCESS: 'EDITAR_MIEMBRO_SUCCESS',
    EDITAR_MIEMBRO_ERROR: 'EDITAR_MIEMBRO_ERROR',
    OBTENER_MIEMBRO: 'OBTENER_MIEMBRO',
    OBTENER_MIEMBRO_SUCCESS: 'OBTENER_MIEMBRO_SUCCESS',
    OBTENER_MIEMBRO_ERROR: 'OBTENER_MIEMBRO_ERROR',
    DELETE_MIEMBRO: 'DELETE_MIEMBRO',
    DELETE_MIEMBRO_SUCCESS: 'DELETE_MIEMBRO_SUCCESS:',
    DELETE_MIEMBRO_ERROR: 'DELETE_MIEMBRO_ERROR',
}

export default TYPES