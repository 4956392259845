/* eslint-disable prettier/prettier */
const TYPES = {
    ADD_EMPLEADO: 'ADD_EMPLEADO',
    ADD_EMPLEADO_SUCCESS: 'ADD_EMPLEADO_SUCCESS',
    ADD_EMPLEADO_ERROR: 'ADD_EMPLEADO_ERROR',
    EDITAR_EMPLEADO: 'EDITAR_EMPLEADO',
    EDITAR_EMPLEADO_SUCCESS: 'EDITAR_EMPLEADO_SUCCESS',
    EDITAR_EMPLEADO_ERROR: 'EDITAR_EMPLEADO_ERROR',
    OBTENER_EMPLEADO_EDITAR: 'OBTENER_EMPLEADO_EDITAR',
    OBTENER_EMPLEADO: 'OBTENER_EMPLEADO',
    OBTENER_EMPLEADO_SUCCESS: 'OBTENER_EMPLEADO_SUCCESS',
    OBTENER_EMPLEADO_ERROR: 'OBTENER_EMPLEADO_ERROR',
    DELETE_EMPLEADO: 'DELETE_EMPLEADO:',
    DELETE_EMPLEADO_SUCCESS: 'DELETE_EMPLEADO_SUCCESS',
    DELETE_EMPLEADO_ERROR: 'DELETE_EMPLEADO_ERROR',
}

export default TYPES
