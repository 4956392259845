/* eslint-disable prettier/prettier */
export const TYPES = {
  ADD_USUARIO: 'ADD_USUARIO',
  ADD_USUARIO_SUCCESS: 'ADD_USUARIO_SUCCESS',
  ADD_USUARIO_ERROR: 'ADD_USUARIO_ERROR',
  OBTENER_USUARIO: 'OBTENER_USUARIO',
  OBTENER_USUARIO_SUCCESS: 'OBTENER_USUARIO_SUCCESS',
  OBTENER_USUARIO_ERROR: 'OBTENER_USUARIO_ERROR',
  OBTENER_USUARIO_EDITAR: 'OBTENER_USUARIO_EDITAR',
  EDITAR_USUARIO: 'EDITAR_USUARIO',
  EDITAR_USUARIO_SUCCESS: 'EDITAR_USUARIO_SUCCESS',
  EDITAR_USUARIO_ERROR: 'EDITAR_USUARIO_ERROR',
  ACTIVAR_USUARIO: 'ACTIVAR_USUARIO',
  ACTIVAR_USUARIO_SUCCESS: 'ACTIVAR_USUARIO_SUCCESS',
  CAMBIO_CLAVE_USUARIO: 'CAMBIO_CLAVE_USUARIO',
  CAMBIO_CLAVE_USUARIO_SUCCESS: 'CAMBIO_CLAVE_USUARIO_SUCCESS',
  CAMBIO_CLAVE_USUARIO_ERROR: 'CAMBIO_CLAVE_USUARIO_ERROR',
  DELETE_USUARIO: 'DELETE_USUARIO',
  DELETE_USUARIO_SUCCESS: 'DELETE_USUARIO_SUCCESS',
  DELETE_USUARIO_ERROR: 'DELETE_USUARIO_ERROR',

  OBTENER_PERFIL: 'OBTENER_PERFIL',
  OBTENER_PERFIL_SUCCESS: 'OBTENER_PERFIL_SUCCESS',
  OBTENER_PERFIL_ERROR: 'OBTENER_PERFIL_ERROR',
}
