/* eslint-disable prettier/prettier */
const TYPES = {
    ADD_JEFE_HOGAR: 'ADD_JEFE_HOGAR',
    ADD_JEFE_HOGAR_SUCCESS: 'ADD_JEFE_HOGAR_SUCCESS',
    ADD_JEFE_HOGAR_ERROR: 'ADD_JEFE_HOGAR_ERROR',
    EDITAR_JEFE_HOGAR: 'EDITAR_JEFE_HOGAR',
    EDITAR_JEFE_HOGAR_SUCCESS: 'EDITAR_JEFE_HOGAR_SUCCESS',
    EDITAR_JEFE_HOGAR_ERROR: 'EDITAR_JEFE_HOGAR_ERROR',
    OBTENER_JEFE_HOGAR: 'OBTENER_JEFE_HOGAR',
    OBTENER_JEFE_HOGAR_SUCCESS: 'OBTENER_JEFE_HOGAR_SUCCESS',
    OBTENER_JEFE_HOGAR_ERROR: 'OBTENER_JEFE_HOGAR_ERROR',
    OBTENER_JEFE_HOGAR_BY_ID: 'OBTENER_JEFE_HOGAR_BY_ID',
    OBTENER_JEFE_HOGAR_BY_ID_SUCCESS: 'OBTENER_JEFE_HOGAR_BY_ID_SUCCESS',
    OBTENER_JEFE_HOGAR_BY_ID_ERROR: 'OBTENER_JEFE_HOGAR_BY_ID_ERROR',
    DELETE_JEFE_HOGAR: 'DELETE_JEFE_HOGAR',
    DELETE_JEFE_HOGAR_SUCCESS: 'DELETE_JEFE_HOGAR_SUCCESS:',
    DELETE_JEFE_HOGAR_ERROR: 'DELETE_JEFE_HOGAR_ERROR',

    TRASLADO_JEFE_HOGAR: 'TRASLADO_JEFE_HOGAR',
    TRASLADO_JEFE_HOGAR_SUCCESS: 'TRASLADO_JEFE_HOGAR_SUCCESS',
    TRASLADO_JEFE_HOGAR_ERROR: 'TRASLADO_JEFE_HOGAR_ERROR',

    BUSQUEDA_JEFE_HOGAR: 'BUSQUEDA_JEFE_HOGAR',
    BUSQUEDA_JEFE_HOGAR_SUCCESS: 'BUSQUEDA_JEFE_HOGAR_SUCCESS',
    BUSQUEDA_JEFE_HOGAR_ERROR: 'BUSQUEDA_JEFE_HOGAR_ERROR',
}

export default TYPES
