/* eslint-disable prettier/prettier */
const TYPES = {
  OBTENER_MUNICIPIO: 'OBTENER_MUNICIPIO',
  OBTENER_MUNICIPIO_SUCCESS: 'OBTENER_MUNICIPIO_SUCCESS',
  OBTENER_MUNICIPIO_ERROR: 'OBTENER_MUNICIPIO_ERROR',
  OBTENER_BARRIOVEREDA: 'OBTENER_BARRIOVEREDA',
  OBTENER_BARRIOVEREDA_SUCCESS: 'OBTENER_BARRIOVEREDA_SUCCESS',
  OBTENER_BARRIOVEREDA_ERROR: 'OBTENER_BARRIOVEREDA_ERROR',
  OBTENER_CORREGIMIENTO: 'OBTENER_CORREGIMIENTO',
  OBTENER_CORREGIMIENTO_SUCCESS: 'OBTENER_CORREGIMIENTO_SUCCESS',
  OBTENER_CORREGIMIENTO_ERROR: 'OBTENER_CORREGIMIENTO_ERROR',
  OBTENER_TIPODOCUMENTO: 'OBTENER_TIPODOCUMENTO',
  OBTENER_TIPODOCUMENTO_SUCCESS: 'OBTENER_TIPODOCUMENTO_SUCCESS',
  OBTENER_TIPODOCUMENTO_ERROR: 'OBTENER_TIPODOCUMENTO_ERROR',
  OBTENER_ESCOLARIDAD: 'OBTENER_ESCOLARIDAD',
  OBTENER_ESCOLARIDAD_SUCCESS:'OBTENER_ESCOLARIDAD_SUCCESS',
  OBTENER_ESCOLARIDAD_ERROR:'OBTENER_ESCOLARIDAD_ERROR' ,
  OBTENER_ORIENTACION_SEXUAL:'OBTENER_ORIENTACION_SEXUAL',
  OBTENER_ORIENTACION_SEXUAL_SUCCESS:'OBTENER_ORIENTACION_SEXUAL_SUCCESS',
  OBTENER_ORIENTACION_SEXUAL_ERROR:'OBTENER_ORIENTACION_SEXUAL_ERROR',
  OBTENER_PARENTESCO: 'OBTENER_PARENTESCO',
  OBTENER_PARENTESCO_SUCCESS: 'OBTENER_PARENTESCO_SUCCESS',
  OBTENER_PARENTESCO_ERROR: 'OBTENER_PARENTESCO_ERROR',
}


export default TYPES
