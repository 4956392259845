/* eslint-disable prettier/prettier */
export const TYPES = {
  ADD_ASOCIACION: 'ADD_ASOCIACION',
  ADD_ASOCIACION_SUCCESS: 'ADD_ASOCIACION_SUCCESS',
  ADD_ASOCIACION_ERROR: 'ADD_ASOCIACION_ERROR',
  OBTENER_ASOCIACION: 'OBTENER_ASOCIACION',
  OBTENER_ASOCIACION_SUCCESS: 'OBTENER_ASOCIACION_SUCCESS',
  OBTENER_ASOCIACION_ERROR: 'OBTENER_ASOCIACION_ERROR',
  OBTENER_ASOCIACION_EDITAR: 'OBTENER_ASOCIACION_EDITAR',
  EDITAR_ASOCIACION: 'EDITAR_ASOCIACION',
  EDITAR_ASOCIACION_SUCCESS: 'EDITAR_ASOCIACION_SUCCESS',
  EDITAR_ASOCIACION_ERROR: 'EDITAR_ASOCIACION_ERROR',
  ACTIVAR_ASOCIACION: 'ACTIVAR_ASOCIACION',
  ACTIVAR_ASOCIACION_SUCCESS: 'ACTIVAR_ASOCIACION_SUCCESS',
  DELETE_ASOCIACION: 'DELETEASOCIACION',
  DELETE_ASOCIACION_SUCCESS: 'DELETE_ASOCIACION_SUCCESS',
  DELETE_ASOCIACION_ERROR: 'DELETE_ASOCIACION_ERROR',
}

export default TYPES
